

















































import { Component, Ref, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { NavigationGuardNext, Route } from "vue-router";

import ScenarioForm from "@/application-manager/components/ScenarioForm.vue";
import ScenarioScript from "@/application-manager/components/ScenarioScript.vue";
import InfraCommandsSideBar from "@/application-manager/components/InfraCommandsSideBar.vue";
import ScenarioRequestModel, {
  ScenarioInfoRequestModel,
  ScenarioScriptRequestModel,
} from "@/application-manager/models/ScenarioRequestModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import ScenarioResponseModel from "@/application-manager/models/ScenarioResponseModel";

@Component({
  components: {
    ScenarioForm,
    ScenarioScript,
    InfraCommandsSideBar,
  },
})
export default class ScenarioView extends mixins(UnsavedChangesMixin) {
  @Ref("infraCommandsSideBar") infraCommandsSideBar!: any;

  localScenario = new ScenarioRequestModel();
  isLoading = true;
  isWatchedJurisdiction = false;
  isSideBarVisible = false;
  isSideBarPinned = false;
  containerPaddingRight = 32;
  clickedCommand = "";

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localScenario);
  }

  get scenarioId(): string {
    return this.$route.params.scenarioId;
  }

  get scenario(): ScenarioResponseModel {
    return this.$store.state.scenarioStore.scenario;
  }

  @Watch("scenario", { deep: true })
  private watchScenario(scenario: ScenarioResponseModel) {
    this.localScenario = ScenarioRequestModel.ofSource(scenario);

    if (!this.isWatchedJurisdiction) {
      this.isWatchedJurisdiction = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  async mounted() {
    this.isLoading = true;
    await Promise.all([
      this.$store.dispatch("loadScenario", this.scenarioId),
      this.$store.dispatch("loadInfraCommands"),
    ]);
    this.isLoading = false;

    document.title = this.$lang(
      "documentTitle",
      this.$lang("applicationManager.scenario.scenarioName") +
        this.scenario.name ?? ""
    );
    this.handleWatchingUnsavedChanges();
  }

  beforeDestroy() {
    this.$store.commit("clearErrorValidationMessage");
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  async handleClickToCommand(value: string) {
    // reset value because "watch" doesn't work if the same value
    this.clickedCommand = "";

    await this.$nextTick();
    this.clickedCommand = value;

    if (!this.isSideBarPinned) {
      this.isSideBarVisible = false;
    }
  }

  handleChangeSideBarPinned() {
    this.isSideBarPinned = !this.isSideBarPinned;

    if (this.isSideBarPinned) {
      this.containerPaddingRight += this.infraCommandsSideBar.$el.clientWidth;
    } else {
      this.containerPaddingRight -= this.infraCommandsSideBar.$el.clientWidth;
    }
  }

  async handleValidateScript() {
    await this.$store.dispatch("validateScenarioScript", {
      payload: this.localScenario.script,
      isShowSuccessNotification: true,
    });
  }

  async handleSave(payload: ScenarioInfoRequestModel) {
    await this.$store.dispatch("updateScenarioInfo", payload);
    this.isSavedForm = true;
  }

  async handleSaveScript() {
    await this.$store
      .dispatch("validateScenarioScript", {
        payload: this.localScenario.script,
      })
      .then(
        async () =>
          await this.$store.dispatch(
            "updateScenarioScript",
            ScenarioScriptRequestModel.ofSource(this.localScenario)
          )
      );

    this.isSavedForm = true;
  }
}
