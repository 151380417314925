


















































































import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import { Ace as AceEditor } from "vue2-brace-editor";

import "brace/mode/plain_text";
import "brace/theme/monokai";
import "brace/theme/chrome";

import ScenarioRequestModel from "@/application-manager/models/ScenarioRequestModel";
import { ScenarioValidationScriptModel } from "@/application-manager/models/ScenarioResponseModel";

@Component({
  components: { AceEditor },
})
export default class ScenarioScript extends Vue {
  @Prop() value!: string;
  @Prop() scenario!: ScenarioRequestModel;
  @Prop({ default: "" }) clickedCommand!: string;

  @Ref("aceEditor") readonly aceEditor!: any;

  localValue = "";
  defaultValue = "";
  key = 0;
  hasAnotations = false;

  get aceEditorTheme(): string {
    return this.$vuetify.theme.dark ? "monokai" : "chrome";
  }

  get isValidScript(): boolean {
    return !this.errorValidationMessage;
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.scenarioStore.isSavingScriptInProgress;
  }

  get errorValidationMessage(): ScenarioValidationScriptModel {
    return this.$store.state.scenarioStore.errorValidationMessage;
  }

  @Watch("errorValidationMessage", { deep: true })
  private watchError(value: ScenarioValidationScriptModel) {
    if (!this.isValidScript) {
      const errorLine = this.localValue.split("\n")[value.lineNumber];
      const startIndex = errorLine.indexOf(value.command);
      const endIndex = startIndex + value.command.length - 1;

      this.aceEditor.editor.getSession().setAnnotations([
        {
          row: value.lineNumber,
          column: 0,
          text: value.message,
          type: "error",
        },
      ]);

      this.setMarkerInAceEditor(
        value.lineNumber,
        startIndex,
        endIndex,
        "custom-error-marker"
      );
    } else {
      this.handleClearMarkers("custom-error-marker");
    }
  }

  @Watch("clickedCommand", { deep: true })
  private watchClickedCommand(value: string) {
    const cursorPosition = this.aceEditor.editor.getCursorPosition();
    this.aceEditor.editor.session.insert(cursorPosition, value);
  }

  @Watch("value", { immediate: true })
  async watchValue(value: string) {
    this.localValue = value;
    this.defaultValue = value ? value : "";

    await this.$nextTick();
    this.highlightVariables();
  }

  @Watch("localValue", { deep: true })
  async watchLocalValue(value: string) {
    if (!this.key) {
      this.key++;
    }
    this.highlightVariables();
    this.$emit("input", value);
    this.$store.commit("clearErrorValidationMessage");
  }

  highlightVariables() {
    if (this.aceEditor?.editor && this.localValue) {
      const lines = this.localValue.split("\n");

      this.handleClearMarkers("custom-variable-marker");
      this.handleClearMarkers("custom-variable-marker--secondary");

      lines.forEach((item: string, index: number) => {
        this.setHighlightForVariables(
          item,
          index,
          /\w+(?= *=)/g,
          "custom-variable-marker"
        );
        this.setHighlightForVariables(
          item,
          index,
          /\$\{[^}]+\}/gm,
          "custom-variable-marker--secondary"
        );
      });
    }
  }

  setHighlightForVariables(
    item: string,
    index: number,
    regex: any,
    className: string
  ) {
    const variables: Array<string> | null = item.match(regex);

    if (variables) {
      if (variables.length > 1) {
        variables.forEach((variable: string) => {
          const startIndex = item.indexOf(variable);
          const endIndex = startIndex + variable.length - 1;

          this.setMarkerInAceEditor(index, startIndex, endIndex, className);
        });
      } else {
        const startIndex = item.indexOf(variables[0]);
        const endIndex = startIndex + variables[0].length - 1;

        this.setMarkerInAceEditor(index, startIndex, endIndex, className);
      }
    }
  }

  setMarkerInAceEditor(
    row: number,
    startIndex: number,
    endIndex: number,
    customClass: string
  ) {
    const ace = require("brace");
    const Range = ace.acequire("ace/range").Range;

    this.aceEditor.editor.session.addMarker(
      new Range(row, startIndex, row, endIndex + 1),
      customClass
    );
  }

  handleClearMarkers(customClass: string) {
    const prevMarkers = this.aceEditor.editor.session.getMarkers();

    if (prevMarkers) {
      const prevMarkersArr = Object.entries(prevMarkers)
        .filter(([, value]: any) => value.clazz === customClass)
        .map(([key]: any) => key);

      for (let item of prevMarkersArr) {
        this.aceEditor.editor.session.removeMarker(prevMarkers[item].id);
      }
    }
  }

  onChange(value: string) {
    this.localValue = value;

    this.setErrorMarker();
    this.highlightVariables();
    this.$emit("input", value);
    this.$store.commit("clearErrorValidationMessage");
  }

  setErrorMarker() {
    const foundIndex = this.localValue
      .split("\n")
      .findIndex((item: string) => !!item && item.slice(-1) !== ";");

    if (foundIndex > -1) {
      this.aceEditor.editor.getSession().setAnnotations([
        {
          row: foundIndex,
          column: 0,
          text: this.$lang(
            "applicationManager.scenario.addSemicolonErrorMessage"
          ),
          type: "error",
        },
      ]);
      this.hasAnotations = true;
    } else {
      this.aceEditor.editor.getSession().setAnnotations([
        {
          type: "",
        },
      ]);
      this.hasAnotations = false;
    }
  }

  @Emit("save") emitSave(): string {
    return this.localValue;
  }

  @Emit("validateScript") emitValidateScript() {
    return;
  }
}
